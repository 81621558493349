import Vue from "vue";
import popup from "@/components/popup/index.vue";

popup.install = (data) => {
    const Popup = Vue.extend(popup);

    const DOM = new Popup({ data }).$mount();

    const body = document.getElementsByTagName('body')[0];

    body.appendChild(DOM.$el)

    Vue.nextTick(() => {
        DOM.visible = true;
    })
}




const registerPopup = () => {
    Vue.prototype.$popup = popup.install;
}


export { registerPopup };