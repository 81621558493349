import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// 引入flexble
import "@/utils/flexible";
// 初始化样式
import "@/assets/styles/initCss.css";

// 注册vant组件
import "@/utils/useVant.js"

//vue注册全局组件
import "@/utils/registerComponents.js"

// 挂在图片请求的前缀
import config from './server/baseUrl';
Vue.prototype.$ip = config.host;


// 注册弹窗组件
import { registerPopup } from "@/components/popup/js/index.js";
Vue.use(registerPopup)




new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
