import axios from "axios";
import config from "@/server/baseUrl"
import $store from "@/store/index"
import { Toast } from 'vant';
import { $loading, removeLoading } from "@/components/loading/js";
// 不需要错误提示的接口
const noErrorToast = ['task/test/get_temp_record'];
// 不展示loading的接口
const noLoading = ['/psychological/scheduling/get_message', '/psychological/scheduling/month_scheduling', '/psychological/scheduling/log_describe', 'task/test/save_records'];
const http = axios.create({
    baseURL: config.host,
    timeout: 600000,
    headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    },
})


//请求拦截器
http.interceptors.request.use(config => {
    config.headers['Authorization'] = `Bearer ${$store.state.INFO.token}`;
    if (!noLoading.includes(config.url)) {
        if (!document.getElementsByClassName('loading')[0]) {
            $loading({ visible: true });
        }
    }
    return config;
})


//相应拦截器
http.interceptors.response.use(response => {
    // 错误消息提示
    if (response.data.code == 0) {
        if (!noErrorToast.includes(response.config.url)) Toast.fail({ message: response.data.message });
    }          
    if (!noLoading.includes(response.config.url)) removeLoading();                                    
                                         
    return response;
})



export default http;

