import "vant/lib/index.css";//引入vant样式
import Vue from "vue";
//注册组件
import { Dialog, DatetimePicker, RadioGroup, Radio, Overlay, Calendar, Icon, Cell, DropdownMenu, DropdownItem, Loading, Sidebar, SidebarItem, Tabs, Tab, Cascader, CellGroup, Field, Toast, Swipe, SwipeItem, Popup, Picker } from 'vant';


Vue.use(Field);
Vue.use(Toast);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(CellGroup);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Cascader);
Vue.use(Tabs);
Vue.use(SidebarItem);
Vue.use(Sidebar);
Vue.use(Tab);
Vue.use(Loading);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Cell);
Vue.use(Icon);
Vue.use(Calendar);
Vue.use(Overlay);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(DatetimePicker);
Vue.use(Dialog);

