<template>
  <div class="flex_row flex_align_center">
    <img
      @click="item.status = !item.status"
      :src="
        require(`@/assets/images/common/checkbox${
          item.status == false ? 1 : 11
        }.png`)
      "
      alt=""
    />
    <div class="label" @click="item.status = !item.status">
      {{ item.label }}
    </div>
  </div>
</template>
  
  <script>
export default {
  props: {
    selected: {
      Type: Boolean,
      default: () => false,
    },
    item: {
      Type: Object,
      default: () => {
        return {
          label: "",
          id: "",
          status: false,
        };
      },
    },
  },
  watch: {
    selected: {
      immediate: true,
      handler: function (nV) {
        this.status = nV;
      },
    },
    status(nV) {
      this.$emit("getStaus", this.status);
      this.$emit("selected", item.id);
    },
  },
  data() {
    return {
      // 状态
      status: false,
    };
  },
};
</script>
  
  <style lang="less" scoped>
div {
  img {
    width: 14.58px;
    height: 13.58px;
  }
  .label {
    font-size: 15.28px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: bold;
    text-align: left;
    color: #333333;
    margin-left: 6.94px;
    white-space: nowrap;
  }
}
</style>