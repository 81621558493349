import http from "@/server/http";

//动态路由
export const getRouterInfo = (params) => {
    return http({
        url: "/login/get_company_info",
        method: "POST",
        data: params,
    })
}
