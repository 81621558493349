
import loading from "@/components/loading/index.vue";
import Vue from "vue";

function $loading(data) {
    const Loading = Vue.extend(loading);
    const DOM = new Loading({ data }).$mount();
    const body = document.getElementsByTagName('body')[0];
    body.appendChild(DOM.$el)
    Vue.nextTick(() => {
        DOM.visible = true;
    })
}

function removeLoading() {
    const DOM = document.getElementsByClassName('loading')[0];
    if (DOM) DOM.parentNode.removeChild(DOM);

}

export { $loading, removeLoading };