<template>
  <div class="checkbox flex_center" @click="checkboxChange">
    <img v-if="checkboxStatus" src="@/assets/images/common/opt_of.png" alt="" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      checkboxStatus: false,
    };
  },
  methods: {
    checkboxChange() {
      this.checkboxStatus = !this.checkboxStatus;
      this.$emit("getCheckboxValue", this.checkboxStatus);
    },
  },
};
</script>


<style lang="less" scoped>
div {
  width: 16px;
  height: 16px;
  border-radius: 5px;
  border: 1px solid #999999;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>