import navigate from "@/components/navigate/index.vue"
import picker from "@/components/picker/index.vue"
import checkbox from "@/components/checkbox/index.vue"
import vswitch from "@/components/switch/index.vue"
import checkboxBlue from "@/components/checkboxBlue/index.vue"
import radio from "@/components/radio/index.vue"
import Vue from "vue"

Vue.component('navigate', navigate);
Vue.component('checkbox', checkbox);
Vue.component('v-picker', picker);
Vue.component('v-switch', vswitch);
Vue.component('v-blue-checkbox', checkboxBlue);
Vue.component('v-radio', radio);


