<template>
  <div class="header">
    <div v-if="isVisibleBack" class="back flex_center" @click="back">
      <img src="@/assets/images/common/back.png" alt="" />
    </div>

    <div class="title">
      <slot></slot>
    </div>

    <div class="right vertical_center" @click="$emit('rightClick')">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisibleBack: {
      Type: Boolean,
      default: () => true,
    },
  },
  methods: {
    back() {
      if (
        this.$route.name == "normalAnswer" ||
        this.$route.name == "teamAnswer"
      )
        return this.$emit("answerBack");

      this.$router.back();
    },
  },
};
</script>
<style lang="less" scoped>
.header {
  height: 60px;
  display: flex;
  position: relative;
  background-color: #fff;
  .back {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    img {
      width: 9px;
      height: 16px;
    }
  }
  .title {
    margin: 0 auto;
    line-height: 60px;
    height: 18.75px;
    font-size: 18.75px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    color: #333333;
  }
  .right {
    width: auto;
    height: auto;
    right: 13px;

    font-size: 15.97px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    text-align: left;
    color: #333333;
    line-height: 25px;
  }
}
</style>