<template>
  <div class="flex_row flex_align_center" @click="confirm">
    <input
      type="radio"
      :value="val"
      :name="name"
      :id="idText"
      v-model="newValue"
    />
    <label :for="idText">{{ text }}</label>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      Type: [Number, Boolean, Object,String],
      default: () => 0,
    },
    val: {
      Type: [Boolean, Number, String,Object],
      default: () => "",
    },
    idText: {
      Type: String,
      dfeault: () => "",
    },
    name: {
      Type: String,
      default: () => "",
    },
    text: {
      Type: String,
      default: () => "",
    },
  },
  data() {
    return {
      _val: false,
    };
  },
  computed: {
    newValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    value: {
      immediate: true,
      handler: function (nV) {
        this._val = nV;
        console.log(nV);
      },
    },
    // val: {
    //   immediate: true,
    //   handler: function (nV) {
    //     this.$emit("selected", nV);
    //   },
    // },
  },
  methods: {
    confirm() {
      this.$emit("selected", this.val);
    },
  },
};
</script>
<style lang="less" scoped>
@import "./css/radio.less";
</style>