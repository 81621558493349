import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 当前的所处导航的位置
    CURRENT_NAV: "",
    INFO: {}
  },
  getters: {
  },
  mutations: {
    //存储登录的账号信息
    SET_INFO(state, params) {
      state.INFO = params;
    },
    // 清空登录的信心
    CLEAR_INFO(state) {
      state.INFO = {};
    },
    //设置导航位置
    SET_NAV_POSITION(state, params) {
      state.CURRENT_NAV = params;
    }

  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState({
    storage: window.localStorage, // 或者 localStorage
  })]
})
