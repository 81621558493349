<template>
  <van-popup v-model="showPicker" round position="bottom">
    <van-picker
      show-toolbar
      :columns="columns"
      @cancel="onCancel"
      @confirm="onConfirm"
      :value-key="valueKey"
    />
  </van-popup>
</template>

<script>
export default {
  props: {
    isVisible: {
      Type: Boolean,
      default: () => false,
    },
    columns: {
      Type: Array,
      default: () => [],
    },
    valueKey: {
      Type: String,
      default: () => "",
    },
  },
  data() {
    return {
      showPicker: false,
    };
  },
  watch: {
    isVisible: {
      immediate: true,
      handler: function (nV) {
        this.showPicker = nV;
      },
    },
    showPicker(nV) {
      if (!nV) {
        this.$emit("cancel");
      }
    },
  },
  methods: {
    onConfirm(e) {
      this.$emit("confirm", e);
    },
    onCancel(e) {
      this.$emit("cancel");
    },
  },
};
</script>

<style>
</style>