import Vue from 'vue'
import VueRouter from 'vue-router'
import { getRouterInfo } from '@/api/addRouter'
Vue.use(VueRouter)





const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import("@/views/login/index.vue"),
    meta: {
      title: "登录"
    }
  },
  // 账号注册
  {
    path: '/register/:id',
    name: 'register',
    component: () => import("@/views/register/index.vue"),
    meta: {
      title: "注册"
    }
  },
  // 忘记密码
  {
    path: '/forget/:id',
    name: 'forget',
    component: () => import("@/views/forget/index.vue"),
    meta: {
      title: "密码找回"
    }
  },
  // 填写申诉找回
  {
    path: '/appeal/:id',
    name: 'appeal',
    component: () => import("@/views/appeal/index.vue"),
    meta: {
      title: "填写申诉找回"
    }
  },
  // 短信验证找回
  {
    path: '/note',
    name: 'note',
    component: () => import("@/views/note/index.vue"),
    meta: {
      title: "短信验证找回"
    }
  },

  // 导航页
  {
    path: '/nav',
    name: 'nav',
    component: () => import("@/views/nav/index.vue"),
    meta: {
      title: "导航"
    },
    children: [
      // 我的
      {
        path: "my",
        name: 'my',
        component: () => import("@/views/nav/my/home/index.vue"),
        meta: {
          title: "我的"
        },
      },
      {
        path: 'home',
        name: 'home',
        component: () => import("@/views/nav/home/index.vue"),
        meta: {
          title: "首页"
        }
      },
      {
        path: 'evaluateList',
        name: 'evaluateList',
        component: () => import("@/views/nav/evaluate/evaluteList/index.vue"),
        meta: {
          title: "心理测评列表"
        }
      },
      // 预约咨询
      {
        path: 'consult',
        name: 'consult',
        component: () => import("@/views/nav/consult/home/index.vue"),
        meta: {
          title: "预约咨询"
        }
      },
      // 功能中心
      {
        path: 'functionCenter',
        name: 'functionCenter',
        component: () => import("@/views/nav/functionCenter/home/index.vue"),
        meta: {
          title: "功能中心"
        }
      },
    ]
  },
  // 测评普通测评详情
  {
    path: '/normalEvaluateDetail/:sid/:tid/:trid/:count',
    name: 'normalEvaluateDetail',
    component: () => import("@/views/nav/evaluate/evaluateDetail/index.vue"),
    meta: {
      title: "测评量表详情"
    },
  },

  //团体测评详情
  {
    path: '/teamEvaluateDetail/:sid/:tid/:trid/:pc',
    name: 'teamEvaluateDetail',
    component: () => import("@/views/nav/evaluate/evaluateTeamDetail/index.vue"),
    meta: {
      title: "团体测评量表详情"
    },
  },
  // 个人测评
  {
    path: '/normalAnswer/:sid/:tid/:rid/:trid',
    name: 'normalAnswer',
    component: () => import("@/views/nav/evaluate/normalAnswer/index.vue"),
    meta: {
      title: "个人答题"
    },
  },
  // 团体测评
  {
    path: '/teamAnswer/:sid/:tid/:rid/:trid/:relevance_id/:factor_id',
    name: 'teamAnswer',
    component: () => import("@/views/nav/evaluate/teamAnswer/index.vue"),
    meta: {
      title: "团体测评"
    },
  },

  // 线上咨询
  {
    path: '/onlineConsultation',
    name: 'onlineConsultation',
    component: () => import("@/views/nav/consult/consult/index.vue"),
    meta: {
      title: "线上咨询"
    },
  },
  // 发起咨询

  {
    path: '/launch/:id',
    name: 'launch',
    component: () => import("@/views/nav/consult/launch/index.vue"),
    meta: {
      title: "发起线上咨询"
    },
  },
  // 线下预约
  {
    path: '/appoint',
    name: 'appoint',
    component: () => import("@/views/nav/consult/appoint/index.vue"),
    meta: {
      title: "线下预约"
    },
  },
  // 线下预约
  {
    path: '/launch2/:id',
    name: 'launch2',
    component: () => import("@/views/nav/consult/launch2/index.vue"),
    meta: {
      title: "本人他人预约"
    },
  },
  // 预约成功！
  {
    path: '/success',
    name: 'success',
    component: () => import("@/views/nav/consult/success/index.vue"),
    meta: {
      title: "预约成功"
    },
  },



  // 线上咨询记录
  {
    path: '/consultationRecord',
    name: 'consultationRecord',
    component: () => import("@/views/nav/functionCenter/consultationRecord/index.vue"),
    meta: {
      title: "线上咨询记录"
    },
  },
  // 线上咨询记录详情
  {
    path: '/consultationRecordDetail/:id/:name/:cid',
    name: 'consultationRecordDetail',
    component: () => import("@/views/nav/functionCenter/consultationRecordDetail/index.vue"),
    meta: {
      title: "线上咨询记录详情"
    },
  },
  // 个人资料
  {
    path: '/personInfo',
    name: 'personInfo',
    component: () => import("@/views/nav/my/personInfo/index.vue"),
    meta: {
      title: "个人资料"
    },
  },
  // 管理登录方式
  {
    path: '/manageLogin',
    name: 'manageLogin',
    component: () => import("@/views/nav/my/manageLogin/index.vue"),
    meta: {
      title: "管理登录方式"
    },
  },
  // 修改登录密码
  {
    path: '/updatePwd',
    name: 'updatePwd',
    component: () => import("@/views/nav/my/updatePwd/index.vue"),
    meta: {
      title: "修改登录密码"
    },
  },
  // 消息列表
  {
    path: '/messageList',
    name: 'messageList',
    component: () => import("@/views/nav/my/messageList/index.vue"),
    meta: {
      title: "消息列表"
    },
  },
  // 消息详情
  {
    path: '/messageDetail',
    name: 'messageDetail',
    component: () => import("@/views/nav/my/messageDetail/index.vue"),
    meta: {
      title: "消息详情"
    },
  },

  // 用户档案
  {
    path: '/userRecord',
    name: 'userRecord',
    component: () => import("@/views/nav/functionCenter/userRecord/home/index.vue"),
    meta: {
      title: "用户档案"
    },
  },

  // 用户档案- 基本资料
  {
    path: '/baseData',
    name: 'baseData',
    component: () => import("@/views/nav/functionCenter/userRecord/baseData/index.vue"),
    meta: {
      title: "用户档案-基本资料"
    },
  },
  // 用户档案- 健康资料
  {
    path: '/health',
    name: 'health',
    component: () => import("@/views/nav/functionCenter/userRecord/health/index.vue"),
    meta: {
      title: "用户档案-健康资料"
    },
  },

  // 普通测评记录
  {
    path: '/evaluateRecord',
    name: 'evaluateRecord',
    component: () => import("@/views/nav/evaluate/evaluateRecord/normalEvaluateRecord/index.vue"),
    meta: {
      title: "普通测评记录"
    }
  },
  // 咨询师详情
  {
    path: '/consultantInfo/:id',
    name: 'consultantInfo',
    component: () => import("@/views/nav/functionCenter/consultantInfo/index.vue"),
    meta: {
      title: "咨询师详情"
    },
  },
  // 查看测评结果
  {
    path: '/testResult/:type/:id',
    name: 'testResult',
    component: () => import("@/views/nav/functionCenter/testResult/index.vue"),
    meta: {
      title: "查看测评结果"
    },
  },
  // 近况自评表
  {
    path: '/selfAssessment/:type/:id',
    name: 'selfAssessment',
    component: () => import("@/views/nav/functionCenter/selfAssessment/index.vue"),
    meta: {
      title: "近况自评表"
    },
  },
  // 线下预约记录
  {
    path: '/appointmentRecord',
    name: 'appointmentRecord',
    component: () => import("@/views/nav/functionCenter/appointmentRecord/index.vue"),
    meta: {
      title: "线下预约记录"
    },
  },
  // 赴约前置任务
  {
    path: '/appointmentPreTask/:id',
    name: 'appointmentPreTask',
    component: () => import("@/views/nav/functionCenter/appointmentPreTask/index.vue"),
    meta: {
      title: "赴约前置任务"
    },
  },
  // 取消预约申请
  {
    path: '/cancelAppointmentApplication/:id',
    name: 'cancelAppointmentApplication',
    component: () => import("@/views/nav/functionCenter/cancelAppointmentApplication/index.vue"),
    meta: {
      title: "取消预约申请"
    },
  },
  // 查看测评结果
  {
    path: '/evaluateExport/:id',
    name: 'evaluateExport',
    component: () => import("@/views/nav/evaluate/evaluateExport/index.vue"),
    meta: {
      title: "查看测评结果"
    },
  },
  // 团体测评报告
  {
    path: '/teamEvaluateExport',
    name: 'teamEvaluateExport',
    component: () => import("@/views/nav/evaluate/teamEvaluateExport/index.vue"),
    meta: {
      title: "团体测评报告列表"
    },
  },
  {
    path: '/teamEvaluateExportDetail/:id/:tid',
    name: 'teamEvaluateExportDetail',
    component: () => import("@/views/nav/evaluate/teamEvaluateExportDetail/index.vue"),
    meta: {
      title: "团体测评报告详情"
    }
  },
  // 预约记录详情
  {
    path: '/appointmentRecordDetail/:id',
    name: 'appointmentRecordDetail',
    component: () => import("@/views/nav/functionCenter/appointmentRecordDetail/index.vue"),
    meta: {
      title: "预约记录详情"
    },
  },
  // 编辑教育情况
  {
    path: '/education',
    name: 'education',
    component: () => import("@/views/nav/functionCenter/userRecord/education/index.vue"),
    meta: {
      title: "预约记录详情"
    },
  },
  // 编辑残障等级
  {
    path: '/disability',
    name: 'disability',
    component: () => import("@/views/nav/functionCenter/userRecord/disability/index.vue"),
    meta: {
      title: "编辑残障等级"
    },
  },
  // 编辑家庭情况
  {
    path: '/family',
    name: 'family',
    component: () => import("@/views/nav/functionCenter/userRecord/family/index.vue"),
    meta: {
      title: "编辑家庭情况"
    },
  },
  // 编辑家庭访谈
  {
    path: '/familyInterview',
    name: 'familyInterview',
    component: () => import("@/views/nav/functionCenter/userRecord/familyInterview/index.vue"),
    meta: {
      title: "编辑家庭访谈"
    },
  },
  // 精神状态编辑
  {
    path: '/mentalState',
    name: 'mentalState',
    component: () => import("@/views/nav/functionCenter/userRecord/mentalState/index.vue"),
    meta: {
      title: "编辑精神状态"
    },
  },


]




const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})




getRouterInfo().then(({ data }) => {

  let temp = data.data;
  temp.forEach(item => {
    const r = {
      path: '/' + item.routing_address,
      name: item.routing_address,
      component: () => import("@/views/login/index.vue"),
      meta: {
        ...item
      }
    };
    router.addRoute(r)
  });

});



export default router
