<template>
  <div @click="switchOperate">
    <img
      :src="
        switchVal
          ? require('@/assets/images/my/manageLogin/switch2.png')
          : require('@/assets/images/my/manageLogin/switch1.png')
      "
      alt=""
    />
  </div>
</template>

<script>
export default {
  props: {
    val: {
      Type: Boolean,
      default: () => false,
    },
  },
  watch: {
    val: {
      immediate: true,
      handler: function (nV) {
        this.switchVal = nV;
      },
    },
  },
  data() {
    return {
      // 开关
      switchVal: false,
    };
  },
  methods: {
    // 开关
    switchOperate() {
      this.switchVal = !this.switchVal;
      this.$emit("getVal", this.switchVal);
    },
  },
};
</script>

<style lang="less" scoped>
div {
  img {
    width: 38px;
    height: 20px;
  }
}
</style>