<template>
  <div class="mask flex_center" v-if="visible">
    <div class="popup flex_column flex_align_center between">
      <div class="title">{{ title }}</div>
      <div class="message" v-html="message"></div>
      <div class="flex_row flex_align_center">
        <div class="confirm btn" @click="confirm">确定</div>
        <div class="cancel" @click="cancel">取消</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 标题
      title: "提示",
      // 显示弹窗
      visible: false,
      // 消息
      message: "",
      // 确实操作
      confirmOperate: () => {},
    };
  },
  methods: {
    cancel() {
      this.visible = false;
    },
    confirm() {
      this.confirmOperate();
      this.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.5);
  .popup {
    width: 333.33px;
    height: 208.33px;
    background: #ffffff;
    border-radius: 10.42px;
    .title {
      font-size: 18.75px;
      font-family: Microsoft YaHei, Microsoft YaHei-Regular;
      font-weight: 400;
      text-align: center;
      color: #333333;
      margin: 15px 0 0px;
    }
    .confirm,
    .cancel {
      font-size: 16.67px;
      font-family: Microsoft YaHei, Microsoft YaHei-Regular;
      font-weight: 400;
      text-align: center;
      color: #ffffff;
      line-height: 48px;
    }
    .confirm {
      width: 166.67px;
      height: 48.61px;
      border-radius: 10.42px 0px 0px 10.42px;
    }
    .cancel {
      width: 166.67px;
      height: 48.61px;
      background: #f5f5f5;
      border-radius: 0px 10.42px 10.42px 0px;
      color: #333333;
    }
    .message {
      margin: 0 auto;
      text-align: center;
      width: 270.14px;
      font-size: 16.67px;
      font-family: Microsoft YaHei, Microsoft YaHei-Regular;
      font-weight: 400;
      color: #333333;
      line-height: 33.33px;
    }
  }
}
</style>